@font-face {
  font-family: 'Jameel Noori Nastaleeq';
  src: url('/src/fonts/JameelNooriNastaleeq.woff2') format('woff2'),
      url('/src/fonts/JameelNooriNastaleeq.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

.font-jameel{
  font-family: 'Jameel Noori Nastaleeq';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: hsl(220, 13%, 18%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bg-blue{
  background-color: #2F3A88;
}

.bg-search{
  background-color: #f3f3f4;
}

.bg-orange{
  background-color: #F29D3A;
}

.text-blue{
  color: #2F3A88;
}

.text-orange{
  color: #F29D3A;
}

.h-video{
  height: 18rem;
}

.h-about{
  height: 28rem;
}

.img-about{
  right: 100px;
  top: 15px;
}

.text-quote{
  font-size: 60px;
}

/* .bg-image{
  background-image: url("../public/events/event6.jpg");
} */

.link-home:hover::after {
  content: "Home";
  position: absolute;
  font-size: 12px;
  color: white;
  margin-left: 10px;
  background-color: #FF8C8C;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: black;
}

.link-about:hover::after {
  content: "About us";
  width: fit-content;
  position: absolute;
  font-size: 12px;
  color: white;
  margin-left: 10px;
  margin-top: 5px;
  background-color: #FF8C8C;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: black;
}

.link-gateway:hover::after {
  content: "Knowledge Gateway";
  width: fit-content;
  position: absolute;
  font-size: 12px;
  color: white;
  margin-left: 10px;
  margin-top: 5px;
  background-color: #FF8C8C;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: black;
}


.link-events:hover::after {
  content: "Events";
  position: absolute;
  font-size: 12px;
  color: white;
  margin-left: 10px;
  margin-top: 5px;
  background-color: #FF8C8C;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: black;
}

.link-services:hover::after {
  content: "Services";
  position: absolute;
  font-size: 12px;
  color: white;
  margin-left: 10px;
  margin-top: 7px;
  background-color: #FF8C8C;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: black;
}

.link-view:hover::after {
  content: "360 View";
  position: absolute;
  width: fit-content;
  font-size: 12px;
  color: white;
  margin-left: 10px;
  margin-top: 5px;
  background-color: #FF8C8C;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: black;
}

.link-publications:hover::after {
  content: "Publications and Groups";
  position: absolute;
  width: fit-content;
  font-size: 12px;
  color: white;
  margin-left: 10px;
  margin-top: 5px;
  background-color: #FF8C8C;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: black;
}

.link-pdf:hover::after{
  content: "Online PDFs";
  position: absolute;
  width: fit-content;
  font-size: 12px;
  color: white;
  margin-left: 10px;
  margin-top: 5px;
  background-color: #FF8C8C;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: black;
}

.best-seller-carousel .owl-nav{
  display: block !important;
  margin-top: -2px !important;
  font-size: 22px !important;
}

.search-icon{
  top: 10px;
  right: 5px;
}

.fy_h16x9, .fy_h4x3 {
  max-width: 100% !important;
}

.h-events{
  height: 16rem !important;
}

@media only screen and (max-width: 1000px) {
  .text-xxs{
    font-size: 8px;
  }

  .h-events{
    height: 8rem !important;
  }

}


.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.slideup, .slidedown {
  max-height: 0;            
  overflow-y: hidden;
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}

.slidedown {            
  max-height: 350px ;                    
} 



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}